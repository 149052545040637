.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;

    /* ad  */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--green);
    /* border-top-left-radius: 50%;
    border-bottom-left-radius: 50%; */
}

.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;

    position: relative;
    align-items: center;

    display: flex;
    /* for setting z-index flex is important */
    justify-content: flex-start;
    
}

.the-best-ad>span{
    z-index: 2;
}

.the-best-ad>div{
    position: absolute;
    left: 8px;
    bottom: 5.7px;
    background-color: var(--green);
    width: 5.4rem;
    height: 80%;
    border-radius: 3rem;
    z-index: 1;
}

.hero-heading{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-heading>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    gap: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
  color: white;  
  background-color: var(--green);
  width: 8rem;
}

.hero-buttons>:nth-child(2){
  color: white;  
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--green);
}








.right-h>.btn{
    position: absolute;
    right:3rem;
    top: 2rem;
    color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate>:nth-child(1){
    width: 2rem;
}

/* .heart-rate>span:nth-of-type(1){ */
.heart-rate>:nth-child(2){
    color: var(--gray);
}

.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    width: 23rem;
    top: 13rem;
    right: 15rem;
}

.hero-image-back{
    position: absolute;
    width: 15rem;
    top: 4rem;
    right: 25rem;
    z-index: -1;
}


.calories{
    position: absolute;
    top: 32rem;
    left: 28rem;

    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    padding: 1rem;
    width: fit-content;
    border-radius: 5px;
}

.calories>img{
    width: 3rem;
}

.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:nth-child(1){
    color: var(--gray);
}

.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}




/* For Responsiveness */

@media screen and (max-width:768px) {
    
    .hero{
        display: flex;
        flex-direction: column;
    }

    .hero-blur{
        width: 14rem;
    }

    .the-best-ad{
        margin-top: 0;
        width: 300px;
        font-size:small;
        align-self: center;
         transform: scale(0.7); 
    }

    .hero-heading{
        font-size: xx-large;
        align-items: center;
        /* For horizontal */

        justify-content: center;
        /* for vertical */
    }

    .hero-heading>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .figures{
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .figures>div>span:nth-of-type(1){
        font-size: large;
    }

    .figures>div>span:nth-of-type(2){
        font-size: small;
    }

    .hero-buttons{
        justify-content: center;
    }

    .right-h{
        background: none;
    }

    .heart-rate{
        left: 1rem;
        top: 2rem;
    }

    .calories{
        /* position: relative; */
        position: absolute;
        top: 5rem;
        /* left: 2rem; */
        right: 4rem;
    }

    .calories>img{
        width: 2rem;
    }

    .calories>div>:nth-child(2){
        color: white;
    }


         /* .hero-image{
            position: relative;
            width: 15rem;
            left: 7rem;
            top: 4rem; 
            align-self: center;
        }
     */
        /* .hero-image-back{
            width: 15rem;
            left: 2rem;
            top: 0rem; 
        }  */
    
    

}

@media screen and (max-width:419px){

    .hero-image{
        position: relative;
        width: 15rem;
        left: 5rem;
        top: 4rem;
        align-self: center;
    }

    .hero-image-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }
}

@media screen and (min-width:419px) and (max-width:768px){

    /* .right-h{
        position: relative;
    } */
    .hero-image{
        position: relative;
        /* position: absolute; */
        width: 20rem;
        left: 11rem;
        top: 2rem;
        align-self: center;
    }

    .hero-image-back{
        width: 17rem;
        left: 9rem;
        top: 0rem;
    }

    .hero-blur2{
        width: 22rem;
        height: 30rem;
        left: 17rem;
        top: 9rem;
    }

}

@media screen and (min-width:769px) and (max-width:1200px){

    .hero-image{
        position: relative;
        /* position: absolute; */
        width:23rem;
        right: 8.5rem;
        top: 13rem;
        align-self: center;
    }

    .hero-image-back{
        width: 15rem;
        left: -11rem;
        top: 11rem;
    }

}

