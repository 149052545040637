.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    flex: 1;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;

    position: relative;

}

.left-j>hr{
    width: 11rem;
    border: 3px solid var(--green);
    border-radius: 20%;
    position: absolute;
    bottom: 6.3rem;
}

.right-j{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    position: relative;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;

    position: absolute;
    right: 10rem;
    top: 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}

::placeholder{
    color: var(--lightgray);
}

#btn-j{
    background-color: var(--green);
    color: white;
}


@media screen and (max-width:768px) {
    
    .Join{
        flex-direction: column;
        gap: 1rem;

        margin-top: -1rem;
    }

    .left-j{
        display: flex;
        gap: 1rem;
        flex-direction: column;
        font-size: x-large;
    }


    .email-container{
        position: relative;
        left: 0;
        padding: 1rem 3.3rem;
    }

    .email-container>button{
        width: 6.1rem;
    }

    ::placeholder{
        font-size: medium;
    }

}

@media screen and (min-width:769px) and (max-width:1200px){

    .left-j{
        display: flex;
        position: relative;
        flex-direction: column;
        font-size: 1.5rem;
        /* align-items: center; */
        justify-content: center;
        gap: 0.5rem;
        
    }

    .left-j>hr{
        position: relative;
    }

    .email-container{
        position: relative;
        left: 0;
        top: 0;
        padding: 0.5rem 1.5rem;
    }

    .email-container>button{
        width: 6.1rem;
    }

    ::placeholder{
        font-size: medium;
    }

}