.Reasons {
  display: flex;
  /* justify-content: space-between; */
  padding: 0 2rem;
  gap: 2rem;
}

.left-r {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  /* gap: 1rem; */



}

.left-r>img {
  object-fit: cover;
}

.left-r>:nth-child(1) {
  width: 15rem;
  height: 35rem;
  grid-row: 1/3;
  grid-column: 1/2;
}

.left-r>:nth-child(2) {
  /* width: auto; */
  width: 30rem;
  height: 20rem;
  grid-column: 2/4;
  grid-row: 1/2;
}

.left-r>:nth-child(3) {
  width: 17rem;
  height: 14rem;
  grid-column: 2/3;
  grid-row: 2/3;
}

.left-r>:nth-child(4) {
  width: 12rem;
  height: 14rem;
  grid-column: 3/4;
  grid-row: 2;
}

.right-r {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}

.right-r>:nth-child(1) {
  /* font-weight: bold; */
  color: var(--green);
}

.right-r>:nth-child(2) {
  /* color: white; */
  font-size: 3rem;
  /* font-weight: bold; */
  display: flex;
  gap: 1rem;
}

.why-us {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.why-us>div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
  align-items: center;

  /* color: white; */
}

.why-us>div>img {
  width: 2rem;
  height: 2rem;
}

.partners {
  color: var(--gray);
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 1.5rem;
}

.partners-images {
  display: flex;
  gap: 1rem;
}

.partners-images>img {
  width: 2.5rem;
}




@media screen and (max-width:540px) {

  .Reasons {
    flex-direction: column;
  }

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r>:nth-child(1) {
    width: 9rem;
    height: 21rem;
  }

  .left-r>:nth-child(2) {
    width: 12rem;
    height: 13rem;
  }

  .left-r>:nth-child(3) {
    width: 12rem;
    height: 7rem;
  }

  .left-r>:nth-child(4) {
    display: none;
    width: 8rem;
    height: 19rem;
  }

  .right-r {
    margin-top: -5rem;
  }

  .right-r>:nth-child(2) {
    /* color: white; */
    font-size: 3rem;
    /* font-weight: bold; */
    flex-direction: column;
  }

}

@media screen and (min-width:530px) and (max-width:768px) {


  .Reasons {
    flex-direction: column;
    gap: 0.1rem;
  }

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r>:nth-child(1) {
    width: 13rem;
    height: 33rem;
  }

  .left-r>:nth-child(2) {
    width: 25rem;
    height: 19rem;
  }

  .left-r>:nth-child(3) {
    width: 25rem;
    height: 13rem;
  }

  .left-r>:nth-child(4) {
    display: none;
    width: 8rem;
    height: 19rem;
  }

  .right-r>:nth-child(2) {
    /* color: white; */
    font-size: 3rem;
    /* font-weight: bold; */
    flex-direction: column;
  }
}

@media screen and (min-width:568px) and (max-width:1350px) {

  .Reasons {}

  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r>:nth-child(1) {
    width: 9rem;
    height: 29rem;
  }

  .left-r>:nth-child(2) {
    width: 23rem;
    height: 17rem;
  }

  .left-r>:nth-child(3) {
    width: 23rem;
    height: 11rem;
  }

  .left-r>:nth-child(4) {
    display: none;
    width: 8rem;
    height: 19rem;
  }

  .right-r>:nth-child(2) {
    /* color: white; */
    font-size: 2.5rem;
    /* font-weight: bold; */
    flex-direction: column;
  }

  .partners{
    position: relative;
    bottom: 1.5rem;
    font-size: 1rem;
  }

  .partners-images{
    position: relative;
    bottom: 1.5rem;
  }

}