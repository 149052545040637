.Plans{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    color: white;
    margin-top: -4rem;
    position: relative;
}

.plans-heading{
    display: flex;
    gap: 3rem;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    font-style: italic;
}

.plans-container{
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
}

.plans-card{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    gap: 2rem;
    padding: 1.5rem;
    width: 23rem;
    /* height: 25rem; */
} 

.plans-container>:nth-child(2){
    transform: scale(1.1);
    background: var(--planCard);
}

.plans-card>:nth-child(1){
    fill: var(--green);
    width: 2rem;
    height: 2rem;
}

.plans-container>:nth-child(2)>:nth-child(1){
    fill: white;
}

.plans-card>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plans-card>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    gap: 1rem;
    align-items: center;
}



.plans-card>:nth-child(5){
    font-size: 0.8rem;
}

.plans-card>:nth-child(5)>span>img{
    width: 0.7rem;
}

.plans-container>:nth-child(2)>button{
    color: var(--green);
}



.plan-blur-1{
    width: 32rem;
    height: 23rem;
    top: 13rem;
    left: 0;
}

.plan-blur-2{
    width: 32rem;
    height: 23rem;
    top: 13rem;
    right: 0;
}



@media screen and (max-width:768px){

    .plans-heading{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    

    .plans-container>:nth-child(2){
        transform: none;
       
    }

}

@media screen and (max-width:448px){
    .plans-card{
        max-width: 15rem;
    }
}

